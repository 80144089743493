// 印章管理
// 3.0 程永雪
import request from '@/utils/request'


const api = {
  // 印章列表
  sealList: "/pc/seal",
  // 印章详情查询
  sealDetail: "/pc/seal/{seal_id}",
  // 表单设计获取企业启用停用的印章
  deactivateSeal: "/pc/seal/flow",
  // 修改印章状态
  sealStatus: "/pc/seal/status",
}

function objToString(obj) {
  const strArr = []
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      strArr.push(`${key}=${obj[key]}`)
    }
  }
  const str = strArr.length ? '?' + strArr.join('&') : ''
  return str
}

// 印章作废
export function sealCancel(params) {
  return request({
    url: `${api.sealStatus}`,
    method: 'PUT',
    // yapi有body用data，没有就用params
    data: params
  })
}

// 添加印章
export function increaseSeal(params) {
  return request({
    url: `${api.sealList}`,
    method: 'POST',
    data: params
  })
}


// 修改印章
export function modifySeal(params) {
  return request({
    url: `${api.sealList}`,
    method: 'PUT',
    data: params
  })
}
// 导出印章列表
export function exportSeals(params) {
  const sessionId = localStorage.getItem('yda-qywx-sessionId')
  if (sessionId != 'null' && sessionId) {
    params.sessionId = sessionId
  }

  return `${process.env.VUE_APP_API_BASE_URL}/pc/seal/derive${objToString(params)}`
}
