<template>
  <a-upload
    :file-list="fileList"
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="action"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <!-- :style="{ backgroundImage: `url(${imageUrl})` }" -->
    <div
      v-if="imageUrl"
      class="img-area"
      @mouseenter="showDelete = true"
      @mouseleave="showDelete = false"
      @click.stop="() => {}"
    >
      <img class="uploadImg" :src="imageUrl" alt="avatar" v-viewer="printImgOptions" />
      <div class="delete-area" v-if="showDelete" @click.stop="removeImage">
        <img :src="deleteIcon" alt="" class="delete-icon" />
      </div>
    </div>
    <div v-else>
      <loading-outlined v-if="loading"></loading-outlined>
      <plus-outlined v-else></plus-outlined>
    </div>
  </a-upload>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { defineComponent, ref } from 'vue'
import deleteIcon from '@/assets/images/delete-icon.png'
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default defineComponent({
  components: {
    LoadingOutlined,
    PlusOutlined
  },
  props: {
    action: String,
    imageUrl: {
      type: String,
      default: () => ''
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:imageUrl', 'fileChange', 'update:fileList'],
  setup(props, { emit }) {
    const showDelete = ref(false)
    const loading = ref(false)
    const handleChange = info => {
      emit('update:fileList', info.fileList)
      if (info.file.status === 'uploading') {
        loading.value = true
        return
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, base64Url => {
          emit('update:imageUrl', base64Url)
          emit('fileChange', info.file)
          loading.value = false
        })
      }
      if (info.file.status === 'error') {
        loading.value = false
        message.error('upload error')
      }
    }
    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        message.error('仅支持png，jpeg，jpg格式！')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        message.error('图片不可超出5MB！')
      }
      return isJpgOrPng && isLt2M
    }
    //移除图片
    const removeImage = () => {
      emit('update:imageUrl', '')
      emit('fileChange', {})
    }
    return {
      loading,
      handleChange,
      beforeUpload,
      deleteIcon,
      showDelete,
      removeImage,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,

        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      }
    }
  }
})
</script>
<style lang="less">
.avatar-uploader > .ant-upload {
  width: 60px;
  height: 60px;
  background: #f3f3f3;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  padding: 0;
  margin: 0;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.ant-upload {
  width: 60px;
  height: 60px;
}

.img-area {
  position: relative;
  width: 60px;
  height: 60px;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  .uploadImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .delete-area {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 60px;
    height: 20px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .delete-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.ant-upload-pcture-card-wrapper.avatar-uploader.upload {
  display: inline-block;
}

.ant-upload-picture-card-wrapper {
  width: 60px;
}
</style>
